@import "variables";

// libraries
@import "~bootstrap/scss/bootstrap.scss";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";

// components
@import "components/header";
@import "components/sidebar";
@import "components/content";
@import "components/page";
@import "components/widget";
@import "components/search-input";

// themes
@import "themes/colors";

@import "react-toastify/dist/ReactToastify.css";

.visually-hidden{
    display: none;
}
.btn-loader{
    height:18px !important;
    width:18px !important;
    border: 0.18em solid currentColor;
    border-right-color: transparent;
}