
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body, h1, h2, h3, h4, h5, h6, p, a, td, button, .h1, .h2, .h3, .h4, .h5, .h6, .display-1, .display-2, .display-3, .display-4, .navbar, .brand, .alert {
    font-family: 'Josefin Sans', sans-serif !important;
}
.detail-heading {
    font-weight: bolder !important;
}
.btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #C2CCCD !important;
    border-color: #C2CCCD !important;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #C2CCCD !important;
    border-color: #C2CCCD !important;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgb(230 213 255);
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(230 213 255)!important;
}
.badge-secondary {
    color: #ffffff;
    background-color: #9e8db5;
}
.btn-secondary {
    color: #fff !important;
    background: #9e8eb7 linear-gradient(180deg, #c2b0dd, #9f8fb8) repeat-x !important;
    border-color: #a090b9 !important;
}
.btn-secondary:hover {
    color: #fff !important;
    background: #9e8eb7 linear-gradient(180deg, #c2b0dd, #9f8fb8) repeat-x !important;
    border-color: #a090b9 !important;
}
.btn {
  padding: 10px 15px;
  font-size: 14px;
}

.dash-graph .cr-widget__icon svg {
    fill: #C2CCCD !important;
}
.cr-sidebar::after {
    z-index: 3;
    background: linear-gradient(180deg, #27282c, #1d1d1e) !important;
    opacity: 1;
}
.bg-light {
  background-color: #f5f4fe !important;
}
.grid {
  position: relative;
  width: 100%;
  background: #fff;
  color: #666666;
  border-radius: 2px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 3%);
}
.card {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: none !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 3%) !important;
margin-bottom:20px !important;
}
.text-card-d {
  font-size: 30px;
  font-weight: 800;
  color: #af9cca !important;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.card-header {
      font-size: 18px;
  font-weight: 600;
  color: #3c3c3c;padding: 20px 15px;
  border-bottom: none;padding-bottom: 10px;
}

.list-group-item {
  padding: 15px 25px;
  font-size: 14px;
}
svg {
  overflow: hidden;
  vertical-align: middle;
  margin-right: 10px;
}
.cr-sidebar .nav .nav-link {
  color: #f8f9fa;
  font-size: 13px;
}
.nav-item .nav-link.active {
  border-radius: 8px;
background:#af9cca !important;
}
.cr-sidebar .nav .nav-link:hover {
  border-radius: 8px;
background:#af9cca !important;
}
.cr-sidebar .nav {
    margin-top: 0px !important;
}
.navbar-brand {
    margin-right: 0px;
}
.navbar {
  padding: 0.5rem 2rem !important;
}
.sidemenu .container-fluid {
	width:auto !important;
}
.mr11 {
	margin-right: 0px !important;
}
.pl-3, .px-3 {
  padding-left: 2rem !important;
}
.pr-3, .px-3 {
  padding-right: 2rem !important;
}
.cr-header {
  margin-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
}
.nproduct {
	padding-bottom:0px;
}
.nproduct .media {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
}
.nproduct .media:last-child {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
}
.nproduct .media {
    align-items: center;
}
.nproduct .media .media-left {
margin-right: 10px !important;
}
.nproduct .media .media-left img {
    width: 40px !important;
    height: 40px;
    object-fit: cover;
    border-radius: 100px !important;
    border: 1px solid #ddd;
    background: #fff;
	margin-bottom: 0px !important;
}
.nproduct .media .media-heading {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize !important;
}
.nproduct .media .text-muted {
    font-size: 13px;
  margin-bottom: 0px;
}
.nproduct .media .h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
    position: relative;
    top: 0px;
	margin-bottom:0px;
}
.n-user tbody td .user-img {
width:35px;
}
.n-user .table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #efefef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #efefef;
}
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #efefef;
font-size: 13px;
}

.form-control {
  display: block !important;
  width: 100% !important;
  height: 50px !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ddd !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.form-control {
outline:none;
}
.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529 !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px 20px !important;
  font-size: 14px !important;
  line-height: 1.5;
  border-radius: 10px !important;
      height: 50px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-secondary {
    color: #fff !important;
    background: #9e8eb7 linear-gradient(180deg, #c2b0dd, #9f8fb8) repeat-x !important;
    border-color: #a090b9 !important;
}

.cate-table svg {
    margin-right: 0px;
}

.clearbtn { color:#fff;}
.applyBtn { color:#fff !important;}

.usertbale .btn-sm, .btn-group-sm > .btn {
  padding: 10px 10px !important;
  font-size: 15px !important;
  line-height: 1.5;
  border-radius: 8px !important;
  text-align: center !important;
  color: #fff !important;
}
.usertbale .btn-sm svg {
  margin-right:0px;
}
.usertbale .rdt_Table img {
  width:35px !important;
height:35px !important;
}
.usertbale .gPyjSA div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.usertbale .gPyjSA div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mt29 {
    margin-top: 26px;
}
.t-white {
 color:#fff !important;
}
.t-black {
 color:#000 !important;
}
.t-pink {
 color:#e48a94 !important;
}
.edit-btn {
  background: #fff;
  margin: 0 15px;
  color: #343a40;
}
.f-13 { font-size:13px;}
.f-16 { font-size:16px;}

.cr-header button {
  border:1px solid #ddd;
}
.cr-header button svg {
  margin-right:0px !important;
}
.s-info .col-md-4 {
 margin-bottom:5px !important;
 font-size:14px;
}   
.addSizeBtn {
  width: 80px !important;
  height: 52px !important;
}
.seller-imgname .media-object {
  width:35px;
  height:35px;
  object:cover;
  border-radius:100px;
}
.seller-imgname .btn-sm, .btn-group-sm > .btn {
  padding: 10px 10px !important;
  font-size: 15px !important;
  line-height: 1.5;
  border-radius: 8px !important;
  text-align: center !important;
  color: #fff !important;
}
.seller-imgname .btn-sm  svg {
  margin-right:0px !important;
}
.s-request .btn-primary {
  padding: 10px 10px !important;
  font-size: 15px !important;
  line-height: 1.5;
  border-radius: 8px !important;
  text-align: center !important;
  color: #fff !important;
}
.s-request .btn-primary svg {
  margin-right:0px !important;
}
.cate-input-search .input-group > .form-control:not(:last-child), .cate-input-search .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.cate-input-search .btn-dark {
  border: 1px solid #000;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.cate-table img {
 width:35px;
 height:35px;
 border-radius:100px;
}
.cate-table .dzcLoW {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.cate-table .btn-secondary {
    padding: 10px 10px !important;
    font-size: 15px !important;
    line-height: 1.5;
    border-radius: 8px !important;
    text-align: center !important;
    color: #fff !important;
    height: 40px;
}
.cate-table .btn-secondary svg {
  margin-right:0px !important;
}
.cate-table .btn-primary {
  padding: 10px 10px !important;
  font-size: 15px !important;
  line-height: 1.5;
  border-radius: 8px !important;
  text-align: center !important;
  color: #fff !important;
  height: 40px;
}
.cate-table .btn-primary svg {
  margin-right:0px !important;
}
.edit-select .css-1s2u09g-control {
  border-radius: 10px;
  min-height: 50px;
  font-size: 14px;
}
.cate-table-tags .btn-secondary {
  padding: 5px 10px !important;
  font-size: 15px !important;
  line-height: 1.5;
  border-radius: 8px !important;
  text-align: center !important;
  color: #fff !important;
}
.cate-table-tags .btn-secondary svg {
  margin-right:0px !important;
}
.brl-0 {
  border-top-left-radius:0px;
  border-bottom-left-radius:0px;
}
.br-10x {
 border-radius:10px;
}
.invoice {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0 !important;
  border-radius: 15px !important;
}

.ordr-table-detail .fhCFWO {
  background-color: #f9f9f9;    padding: 10px 0px;
}
.ordr-table-detail .media-object {
      max-width: 70px !important;
    max-height: 70px !important;
    object-fit: cover;
    border-radius: 10px;
    margin: 10px 0px;
}
.ordr-table-detail .table thead th {
  font-size: 13px;
  background: #9f8fb8;
  color: #fff;
  width: 50%;
  text-align: center;
}
.ordr-table-detail .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #efefef;
  font-size: 13px;
  text-align: center;
}

.tbclass .nav-tabs .nav-link {
  justify-content: center;
}
.tbclass .nav-item .nav-link.active {
  border-radius: 0px;
  background: #af9cca;
  color: #fff !important;
  text-transform: capitalize;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: none;
}
.tbclass .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: none;
  background: #af9cca;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
   cursor:pointer;
}
.tbclass .detail-heading {
font-size:16px;
}
.tbclass .text-muted {
font-size:13px;
}
.tbclass  .btn-secondary svg {
  margin-right:0px !important;
}
.tbclass .btn-secondary {
  padding: 10px 10px !important;
font-size: 15px !important;
  border-radius: 8px !important;
}
.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #a090b9 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.cate-table .btn-secondar svg {
margin-right:0px !important;
}
.fWWeYy {
  padding-right: 30px;
}
.cate-table-pp .btn-secondary {
  padding: 10px 10px !important;
  font-size: 15px !important;
  line-height: 1;
  border-radius: 8px !important;
  text-align: center !important;
  color: #fff !important;
}
.cate-table-pp .btn-secondary svg {
margin-right:0px !important;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #343a40;
}

.s-sort .row [class^='col-'] {
  margin-bottom: 0em;
}
.s-sort {
  padding: 10px 10px;
  font-size: 13px;
  border-radius: 15px !important;
}

.edit-select .css-1pahdxg-control {
  border-radius: 10px;
  min-height: 50px;
  font-size: 14px;
}
.edit-select .css-1s2u09g-control {
  border-radius: 10px;
  min-height: 50px;
  font-size: 14px;
}
.edit-select .css-1s2u09g-control .css-xb97g8 svg {
margin-right:0px !important;
}
.edit-select .css-12jo7m5 {
  line-height: 20px;
}
.languages {
  background: #fff !important;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding-left: 0px;
  list-style: none;
}
.languages > li {
  background: #fff !important;border-radius: 10px;
}
.languages > li > div {
  padding: 5px 10px;
}
.languages > li > div:hover {
  background: #a090b9 !important;
  color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
}
.new-size {
margin: 0px -15px;
}
.new-size .sizeListItem {
  display: flex;
  width: 100%;
  margin: 0px 0px;
  padding: 0px 15px;
  margin-left: 0px;
}
.new-size .sizeArea {
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 40px;
  color: #495057;
  background-color: #ededed;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.new-size .edit-field {
  width: 70px;
  border: none;
  border: 1px solid #ddd;
  outline: none;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
  height: 50px;
}
.new-size .removeSize {
  font-size: 16px;
  cursor: pointer;
  line-height: 40px;
  background: #cdcdcd;
  color: #3c3c3c;
  text-align: center;
  font-size: 24px;
  border-radius: 10px;
  margin-bottom: 0px;
  height: 50px;
}
.new-size .removeSize svg {
  margin-right:0px !important;
}


/*** CHAT CSS ***/


:root {
--bg-page: #ffffff;
--bg-page-darker: #e5ddf1;
--bg-page-darkest: #b3b3b3;
--colour-primary: #af9cca;
--colour-primary-lightest: #e5ddf1;
--colour-primary-darker: #9e8eb7;
--colour-third: #419d78;
--colour-third-lighter: #7bc9aa;
--colour-third-lightest: #e6f7f0;
--colour-text: #696969;
--colour-text-lighter: #9b9b9b;
--colour-text-darker: #626262;
--colour-text-darkest: #363636;
--border-color: #e8e7e7;
--form-radius: 13px;
--search-form-bg-colour: #f2f2f2;
--send-form-bg-colour: #f2f2f2;
--send-btn-box-shadow-colour: #b5bdc5;
--chat-bubble-me: #f2f2f2;
--chat-bubble-you: #9e8eb7;
}
/* ===================================
  Main
====================================== */

.cr-page__title {
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  font-size: 22px !important;
}
.messages-page {
height: 67vh;
}
.messages-page__header {
display: flex;
justify-content: space-between;
align-items: center;
}
.messages-page__title {
color: var(--colour-text-darker);
font-weight: bold;
font-size: 1.5rem;
}
@media screen and (max-width: 1199px) {
.messages-page__title {
  font-size: 1.5rem;
}
}
.messages-page__dark-mode-toogler {
width: 2.6rem;
height: 2.6rem;
padding: 0.35rem;
border-radius: 50%;
border: 1px solid var(--border-color);
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}
.messages-page__dark-mode-toogler:hover {
background-color: var(--colour-primary);
border-color: var(--colour-primary);
}
.messages-page__dark-mode-toogler:hover path {
fill: var(--bg-page-darker);
}
.messages-page__list {
list-style: none;
flex-grow: 1;
overflow-y: auto;
}
.messages-page__list-scroll {
height: 100%;
overflow: hidden;
position: relative;
display: flex;
flex-direction: column;
justify-content: space-between;
padding-top: 15px;
}

.custom-form {
color: var(--colour-text-darkest);
padding: 1.5rem;
border-radius: 13px;
}
.custom-form__search-wrapper, .custom-form__send-wrapper {
width: 100%;
height: 100%;
position: relative;
}
.custom-form__search-wrapper input::-moz-placeholder, .custom-form__send-wrapper input::-moz-placeholder {
color: var(--colour-text-lighter);
font-size: 0.9rem;
}
.custom-form__search-wrapper input:-ms-input-placeholder, .custom-form__send-wrapper input:-ms-input-placeholder {
color: var(--colour-text-lighter);
font-size: 0.9rem;
}
.custom-form__search-wrapper input::placeholder, .custom-form__send-wrapper input::placeholder {
color: var(--colour-text-lighter);
font-size: 0.9rem;
}
.custom-form__search-wrapper input:focus, .custom-form__send-wrapper input:focus {
outline: none;
box-shadow: none;
}
.custom-form__search-wrapper input {
padding-right: 3rem;
background-color: var(--search-form-bg-colour);
border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:-moz-placeholder-shown {
background-color: var(--search-form-bg-colour);
border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:-ms-input-placeholder {
background-color: var(--search-form-bg-colour);
border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:placeholder-shown {
background-color: var(--search-form-bg-colour);
border: 1px solid var(--bg-page);
}
.custom-form__search-wrapper input:focus {
background-color: var(--bg-page);
border-color: var(--border-color);
color: var(--colour-text);
}
.custom-form__send-wrapper input {
padding-right: 6rem;
padding-left: 3.25rem;
background-color: var(--send-form-bg-colour);
border: none;
}
.custom-form__send-wrapper input:focus {
background-color: var(--send-form-bg-colour);
border-color: transparent;
color: var(--colour-text);
}
.custom-form__search-submit {
position: absolute;
top: 0;
right: 0;
height: 100%;
width: 3.5rem;
cursor: pointer;
background-color: transparent;
border: none;
outline: none;
display: flex;
justify-content: center;
align-items: center;
}
.custom-form__search-submit:focus {
outline: none;
border: none;
}
.custom-form__send-submit {
position: absolute;
top: 50%;
right: 0.5rem;
transform: translateY(-50%);
height: 2.3rem;
width: 2.3rem;
display: flex;
justify-content: center;
align-items: center;
background-color: var(--colour-primary);
border-radius: 50%;
box-shadow: 0 3px 3px var(--send-btn-box-shadow-colour);
border: none;
outline: none;
text-align: center;
font-size: 1.2rem;
padding-top: 0.3rem;
color: white;
padding-right: 0.1rem;
}
.custom-form__send-submit:focus {
outline: none;
border: none;
}
.custom-form__send-submit:hover {
background-color: var(--colour-primary-darker);
}
.custom-form__send-img {
position: absolute;
top: 50%;
left: 0.5rem;
transform: translateY(-50%);
width: 2.3rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}
.custom-form__send-emoji {
position: absolute;
top: 50%;
right: 3.2rem;
transform: translateY(-50%);
width: 2.3rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}

.messaging-member {
border-radius: var(--form-radius);
}
.messaging-member:hover {
background-color: var(--bg-page-darker);
}
.messaging-member--new .messaging-member__name {
color: var(--colour-text-darker);
}
.messaging-member--new .messaging-member__message {
color: var(--colour-text-darker);
font-weight: bold;
}
.messaging-member--online .user-status {
background-color: var(--colour-third-lighter);
}
.messaging-member--active {
background-color: var(--colour-primary-lightest);
}
.messaging-member--active:hover {
background-color: var(--colour-primary-lightest);
}
@media screen and (max-width: 767px) {
.messaging-member--active {
  background-color: var(--bg-page);
}
.messaging-member--active:hover {
  background-color: var(--bg-page-darker);
}
}
.messaging-member__wrapper {
cursor: pointer;
padding: 0.5rem 1rem;
border-radius: var(--form-radius);
display: grid;
grid-template-columns: 4rem 4fr;
grid-template-rows: 2rem 2rem;
-moz-column-gap: 1rem;
     column-gap: 1rem;
grid-template-areas: "avatar     name" "avatar     message";
}
@media screen and (max-width: 1199px) {
.messaging-member__wrapper {
  grid-template-columns: 3.5rem 1fr;
  grid-template-rows: 1.75rem 1.75rem;
}
}
@media screen and (max-width: 991px) {
.messaging-member__wrapper {
  grid-template-columns: 3.2rem 1fr;
  grid-template-rows: 1.75rem 1.75rem;
}
}
.messaging-member__avatar {
grid-area: avatar;
position: relative;
}
.messaging-member__avatar img {
border-radius: 50%;
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.messaging-member__name {
font-weight: bold;
grid-area: name;
color: var(--colour-text-darker);
margin-top: auto;
font-size: 0.9rem;
}
.messaging-member__message {
grid-area: message;
white-space: nowrap;
word-break: break-word;
text-overflow: ellipsis;
overflow: hidden;
font-size: 0.9rem;
}

.chat {
height: 100%;
width: 100%;
}
.chat__container {
height: 100%;
width: 100%;
}
.chat__wrapper {
background-color: var(--bg-page);
height: 100%;
width: 100%;
border-left: 1px solid var(--border-color);
border-right: 1px solid var(--border-color);
overflow: hidden;
position: relative;
display: flex;
flex-direction: column;
justify-content: space-between;
}
@media screen and (max-width: 767px) {
.chat__wrapper {
  border-left: none;
  border-right: none;
}
}
.chat__messaging {
width: 100%;
border-bottom: 1px solid var(--border-color);
display: flex;
justify-content: space-between;
align-items: center;
}
.chat__previous {
width: 8%;
min-width: 2rem;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.7rem;
cursor: pointer;
color: var(--colour-primary);
}
.chat__notification {
width: 4%;
min-width: 1.5rem;
}
.chat__notification span {
display: none;
width: 1.4rem;
height: 1.4rem;
text-align: center;
border-radius: 50%;
font-weight: bold;
color: white;
background-color: var(--colour-primary);
font-size: 0.9rem;
}
.chat__notification--new span {
display: block;
}
.chat__infos {
flex-grow: 1;
}
.chat__actions {
font-size: 5px;
height: 2rem;
min-width: 2rem;
color: var(--colour-primary);
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}
.chat__actions ul {
list-style: none;
display: flex;
}
.chat__actions li {
width: 2.6rem;
height: 2.6rem;
padding: 0.35rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-color: var(--bg-page);
}
.chat__actions li + li {
margin-left: 0.3rem;
}
.chat__actions li:hover {
background-color: var(--colour-primary-lightest);
}
.chat__content {
flex-grow: 1;
overflow-y: auto;
}
.chat__list-messages {
list-style: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.chat__list-messages li {
margin-bottom: 0.7rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
}
.chat__list-messages li .chat__bubble {
margin-bottom: 0.2rem;
}
.chat__bubble {
position: relative;
color: var(--colour-text-darkest);
padding: 0.5rem 1rem;
border-radius: 12px;
background-color: var(--bg-page);
max-width: 30rem;
font-size: 0.9rem;
overflow: hidden;
overflow-wrap: break-word;
word-break: break-word;
}
.chat__bubble--you {
margin-right: 2rem;
background-color: var(--chat-bubble-me);
}
.chat__bubble--you p { text-align:left !important;}
.chat__bubble--me {
margin-left: 2rem;
background-color: var(--chat-bubble-you);
align-self: flex-end;
color: white;
}
.chat__time {
font-size: 0.8rem;
color: var(--colour-text-lighter);
align-self: center;
padding-bottom: 0.2rem;
}
.chat__send-container {
display: flex;
justify-content: space-between;
align-items: center;
}
.chat-member__wrapper {
width: 100%;
height: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
}
.chat-member__avatar {
position: relative;
width: 3.5rem;
}
.chat-member__avatar img {
    border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
.chat-member__avatar {
  width: 2.5rem;
}
}
.chat-member__name {
font-weight: bold;
color: var(--colour-text-darker);
margin-top: auto;
white-space: nowrap;
word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
font-size: 1rem;
}
@media screen and (max-width: 1199px) {
.chat-member__name {
  font-size: 0.9rem;
}
}
.chat-member__details {
margin-left: 0.8rem;
display: flex;
justify-content: center;
flex-direction: column;
}
@media screen and (max-width: 767px) {
.chat-member__details {
  margin-left: 1rem;
}
}
.chat-member__age {
font-size: 0.9rem;
color: var(--colour-text-lighter);
position: relative;
}
.chat-member__age::after {
content: " . ";
font-size: 0px;
position: absolute;
top: 50%;
right: -4px;
width: 3px;
height: 3px;
background-color: var(--colour-text-lighter);
border-radius: 50%;
}
.chat-member__status {
color: var(--colour-text-lighter);
font-size: 0.8rem;
}
.chat__profile {
width: 100%;
height: 100%;
max-width: 20rem;
}
.chat--mobile {
position: fixed;
top: 0;
left: 0;
z-index: 10001;
transform: translateY(100%);
display: none;
transition: transform 0.3s ease-in-out 0.1s;
}
.chat--mobile .chat__wrapper {
border-radius: 0;
}
.chat--mobile.chat--show {
display: block;
transform: translateY(0%);
border-radius: 0;
}

.user-profile {
width: 100%;
height: 100%;
position: relative;
overflow: hidden;
overflow-y: auto;
}
.user-profile__wrapper {
position: relative;
height: 100%;
width: 100%;
padding-top: 5rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.user-profile__close {
position: absolute;
top: 1rem;
left: 1.5rem;
width: 2rem;
height: 2rem;
color: var(--colour-primary);
font-size: 1.375rem;
cursor: pointer;
z-index: 10003;
}
.user-profile__avatar {
display: flex;
justify-content: center;
align-items: center;
}
.user-profile__avatar img {
width: 9rem;
border-radius: 50%;
}
.user-profile__name {
font-weight: bold;
margin-top: 0.7rem;
color: var(--colour-text-darker);
word-wrap: break-word;
font-size: 1.15rem;
}
@media screen and (max-width: 767px) {
.user-profile__name {
  font-size: 1.1rem;
}
}
.user-profile__phone {
color: var(--colour-text-darker);
font-size: 0.9rem;
}
.user-profile__details {
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
}
.user-profile__location {
color: var(--colour-text-lighter);
font-size: 0.9rem;
}
.user-profile__description {
text-align: center;
}
.user-profile__description p {
margin-top: 1.3rem;
word-wrap: break-word;
overflow: hidden;
text-overflow: ellipsis;
font-size: 0.9rem;
}
.user-profile__label {
font-size: 0.9rem;
font-weight: bold;
}
.user-profile__tags {
list-style: none;
display: flex;
flex-wrap: wrap;
align-items: center;
}
.user-profile__tags li {
padding: 0.3rem 1rem;
border-radius: 1rem;
margin-right: 0.3rem;
margin-bottom: 0.3rem;
font-size: 0.9rem;
}
.user-profile__tags a:hover {
text-decoration: none;
}
.user-profile__tags--primary li {
background-color: var(--colour-primary-lightest);
color: var(--colour-primary-darker);
}
.user-profile__tags--primary a:hover {
color: var(--colour-primary-darker);
}
.user-profile__tags--secondary li {
background-color: var(--colour-third-lightest);
color: var(--colour-third);
}
.user-profile__tags--secondary a:hover {
color: var(--colour-third);
}
.user-profile--large {
display: none;
position: fixed;
top: 0;
right: 0;
z-index: 10002;
transform: translateX(100%);
transition: transform 0.3s ease-in-out 0.1s;
background-color: var(--bg-page);
box-shadow: -3px 0 3px rgba(0, 0, 0, 0.06);
}
.user-profile--large.user-profile--show {
display: block;
transform: translateX(0%);
border-radius: 0;
}

.user-status {
position: absolute;
right: 0;
bottom: 0;
width: 1rem;
height: 1rem;
background-color: var(--colour-text-lighter);
border: 3px solid white;
border-radius: 50%;
}
.user-status--online {
background-color: var(--colour-third);
}

.svg-icon {
width: 70%;
}
.svg-icon path,
.svg-icon circle {
fill: var(--colour-primary);
}
.svg-icon--send {
width: 60%;
}
.svg-icon--send path,
.svg-icon--send circle {
fill: white;
}
.svg-icon--search {
width: 40%;
}
.svg-icon--search path,
.svg-icon--search circle {
fill: var(--bg-page-darkest);
}
.svg-icon--send-img {
width: 55%;
}
.svg-icon--send-img path,
.svg-icon--send-img circle {
fill: var(--bg-page-darkest);
}
.svg-icon--send-emoji {
width: 60%;
}
.svg-icon--send-emoji path,
.svg-icon--send-emoji circle {
fill: var(--bg-page-darkest);
}
.svg-icon--previous {
width: 55%;
}
.svg-icon--dark-mode {
width: 80%;
}
.svg-icon--dark-mode path,
.svg-icon--dark-mode circle {
fill: var(--colour-primary);
}

.h-100 {
  height: 100% !important;
}
.chat__messaging_bottom_box {
width: 100%;
border-top: 1px solid #e8e7e7;
border-top: 1px solid var(--border-color);
display: flex;
justify-content: space-between;
align-items: center;
}

.noimg {
width:180px;
}
.no-messagecente {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}


/* Styles the thumbnail */

a.lightbox img {
   height: 150px;
   border: 3px solid white;
   box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
   margin: 0px 0px 0px 0px;
   border: 3px solid white;
   box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
   margin: 0px 0px 0px 0px;
}

/* Styles the lightbox, removes it from sight and adds the fade-in transition */

.lightbox-target {
position: fixed;
top: -100%;
width: 100%;
background: rgba(0,0,0,.7);
width: 100%;
opacity: 0;
-webkit-transition: opacity .5s ease-in-out;
-moz-transition: opacity .5s ease-in-out;
-o-transition: opacity .5s ease-in-out;
transition: opacity .5s ease-in-out;
overflow: hidden;

}

/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-target img {
margin: auto;
position: absolute;
top: 0;
left:0;
right:0;
bottom: 0;
max-height: 0%;
max-width: 0%;
border: 3px solid white;
box-shadow: 0px 0px 8px rgba(0,0,0,.3);
box-sizing: border-box;
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;

}

/* Styles the close link, adds the slide down transition */

a.lightbox-close {
display: block;
width:50px;
height:50px;
box-sizing: border-box;
background: white;
color: black;
text-decoration: none;
position: absolute;
top: -80px;
right: 0;
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:before {
content: "";
display: block;
height: 30px;
width: 1px;
background: black;
position: absolute;
left: 26px;
top:10px;
-webkit-transform:rotate(45deg);
-moz-transform:rotate(45deg);
-o-transform:rotate(45deg);
transform:rotate(45deg);
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:after {
content: "";
display: block;
height: 30px;
width: 1px;
background: black;
position: absolute;
left: 26px;
top:10px;
-webkit-transform:rotate(-45deg);
-moz-transform:rotate(-45deg);
-o-transform:rotate(-45deg);
transform:rotate(-45deg);
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target:target {
  opacity: 1;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 999999;
}

.lightbox-target:target img {
max-height: 100%;
max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
top: 0;z-index: 999999;
cursor:pointer;
}
.chat-doc__me{
  align-self: flex-end;
  margin-top: 5px;
}
.chat-doc__you{
  margin-top: 5px;
}

.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}
#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.kJrhuj {
    padding-right: 35px !important;
}
.jVeafb {
    padding: 10px 0px;
}
.badge-secondary {
    position: relative;
    top: -3px;
    line-height: normal;
}
.dash-graph .cr-widget__icon {
    padding-bottom: 0px;
}

.dash-graph .cr-widget__icon  svg {
    fill: #da7588;
}
.seller-imgname .bnAwAJ {
    min-width: 140px;
}
.btnproductdetail {
	display:inline-block;
	float:right;
}
.pad12-20 {
	padding: 12px 20px;
}
.padd14-20 {
	padding: 14px 20px;
}
.cate-table img {
    border: 1px solid #bfbfbf;
}


.messaging-member__name {
    font-weight: bold;
    grid-area: name;
    color: #626262;
    color: var(--colour-text-darker);
    margin-top: auto;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.padding-box {
	  padding: 0px 35px;
      padding-top: 20px;
   }


.css-1s2u09g-control {
    border-radius: 10px !important;
    min-height: 50px !important;
    font-size: 14px;
}
.newlfex {
	flex-wrap:initial !important;
}
.newlfex .form-control {
	border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.newlfex .input-group-text {
	border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
 .custom-form__send-wrapper input {
    padding-right: 6rem !important;
    padding-left: 3.25rem !important;
} 
.custom-form__send-submit {
    background-color: #9b8ab2 !important;
}
.chat__bubble--me {
    background-color: #9b8ab2 !important;
}

.drp-buttons  button.btn-primary{
    height: 35px;
    line-height: 15px;
    background: #a191ba;
    border: 1px solid #a191ba;
}
.font-22 {
	font-size:22px;
}
.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
}
.sizeArea {
    width: 130px !important;
}
.sizeListItem {
    display: flex;
    width: 100%;
    margin: 0px 0 !important;
    padding: 8px 0;
    justify-content: flex-start !important;
}
.edit-field {
	border: 1px solid #ddd !important;
    padding: 0px 10px;
    font-size: 16px;
    height: 30px;
	border-radius:5px;
}
.avlQuantityArea {
    width: auto !important;
    margin-right: 10px !important;
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media screen and (max-width: 991px){
.messaging-member__wrapper {
    grid-template-columns: 3.2rem 1fr;
    grid-template-rows: 1.75rem 1.75rem;
    padding-bottom: 5px;
}
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
  .navbar {
    padding: 0.5rem 1rem;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0px;
  }
  .cr-page.px-3 {
    padding-right: 1rem !important;
  }
   .cr-page.px-3 {
		padding-left: 1rem !important;
   }
   
   .DashboardPage .card {
      margin-bottom: 0px;
   }
   .pad10 {
	   padding:10px;
   }
   .nproduct .media .media-heading {
		font-size: 13px;
	}
    .nproduct .media .text-muted {
		font-size: 12px;
		margin-bottom: 0px;
	}
	.nproduct {
		padding: 0px;
	}
	.nuser-dash {
		padding: 0px;
	}
	.card-header.nuser-title {
       padding: 20px 10px;;
    }
	.user-saerchcar {
		padding:15px;
	}
	.user-saerchcar select {
		padding-right: 0px;
	}
	.user-saerchcar .input-group {
		display: block;
	}
	.user-saerchcar .searcbox-user input {
		width:100%;
		margin-bottom:15px;
	}
	.user-saerchcar .searcbox-user .ml-3, .mx-3 {
		margin-left: 0rem !important;
	}
	.usertable-mobile {
		padding:5px;
	}
	.usertable-mobile .hfFwkx {
		max-width: 60px;
		min-width: 60px;
		display: block;
	}
	.usertable-mobile .emGqmj div:first-child {
		white-space: normal;
		overflow: visible;
		text-overflow: ellipsis;
	}
	.usertable-mobile .jfrgbA {
		padding-left: 10px;
		padding-right: 10px;
	}
	.usertable-mobile .bnAwAJ {
		min-width: 160px;
	}
	.mobile-mb-0 {
		margin-bottom:0px !important;
	}
	.m-leftfloat {
		float:left !important;
	}
	.mp-15 {
		padding:0px 15px !important;
	}
	.storeinpit .form-group {
		margin-bottom: 0rem;
	}
	.imgmobilepreview .drag-area {
		height: 120px;
	}
	.stordetail .f-16 {
		font-size:14px;    margin-right: 20px;
	}
	.stordetail .inline-blck-1 {
		display:inline-block;
	}
	.stordetail  .nav-tabs {
		display: inline-block;
        width: 100%;
	}
	.stordetail  .nav-tabs .nav-item {
		width: 100px !important;
        display: inline-block;
		margin-bottom: 5px;
	}
	.titlepage .cr-page__title {
		font-size: 20px;
		position: relative;
		line-height: 45px;padding-right: 0px;
	}
	.storpayment .card-body {
		padding: 14px;
	}
	.storpayment .detail-heading {
		font-size: 14px;
	}
	.storpayment .text-card-d {
		font-size: 22px;
	}
	.storpayment .card {
		box-shadow: none;
	}
	.cr-page .storpayment > [class^='col-'] {
		padding: 0px 0px;
	}
	.categorietable {
		padding: 15px;
    }
	.categorietable .bMgaAx div:first-child {
		white-space: nowrap;
		overflow: visible;
		text-overflow: initial;
    }
	.brabnttop button {
		margin-bottom:10px;
		margin-right: 10px !important;
	}
	.btnproductdetail {
		float:left;
		margin-top:5px;
	}
	.btnproductdetail .edit-btn {
		margin: 0 15px;
		margin-left:0px;
	}
	.new-size .sizeListItem {
		margin-bottom: 10px;
	}
	.adpdobtn {
		margin-top: 0px !important;
		margin-left: 15px;
		margin-bottom: 15px;
	} 
    .new-size .label1 {
		font-size: 13px;
		font-weight: 600;
	}
	.new-size .sizeArea {
		margin-top:25px;
	}
	.new-size .removeSize {
		margin-top:25px;
	}
	.new-size .edit-field {
		width: 100%;
	}
	.fortgag .form-group {
		margin-bottom:0px;
	}
	.cate-table-tags .szbII {
		min-width: 130px;
		max-width: 130px;
	}
	.cate-table-tags .ceNZgz {
		min-width: 130px;
		max-width: 130px;
	}
	.cate-table .hfFwkx {
		min-width: 70px;
		max-width: 70px;
	}
	.order-rt-table .btORcN {
		max-width: 100%;
		min-width:110px;
		padding: 0px 13px;
	}

	.orderdailinvoce .d-flex {
		display:block !important;
	}
	.orderdailinvoce {
		padding: 15px 0px !important;
	}
	.left-float-orderdtail-invoce {
		float:left;
	}
	.btn-padd button {
		padding:10px 10px;
	}
    .order-rt-req .d-flex {
		display:block !important;
	}
	.order-rt-req  .form-control {
		outline: none;
		padding-right: 0px;
	}
	.padd15both {
		padding:15px !important;
	}
	.overfloscroll {
		overflow-x: scroll;
	}
	.plr-0 {
		padding-left:0px !important;
        padding-right:0px;		
	}
	.objectcover {
		object-fit:cover;
	}
    .plr-5 {    
	   padding-right: 5px !important;
       padding-left: 5px !important;
	}
	.messages-page__list-scroll {
		height: 280px;
        margin-bottom: 0px !important;
		padding-top: 15px;
	}
	.messages-page__list .form-control {
		outline: none;
		margin: 0px 15px;
		width: 90%;
	}
	.chat__messaging {
		background: #fbeeee;
		padding: 10px;
	}
	.chat__content {
		flex-grow: 1;
		overflow-y: auto;
		background: #fff;
		position: relative;
		z-index: 1;padding: 15px !important;
		height: 250px;
	}
	.chat__list-messages {
		padding-left: 0px;
	}
	.messages-page__list {
		padding-bottom: 10px !important;
	}
	.messages-page {
		height: auto;
	}
	.messaging-member__avatar img {
		border-radius: 50%;
		width: 55px;
		height: 55px;
		object-fit: cover;
	}
	.chatdetailuser-csutomer .chat__content {
		padding: 0px !important;
	}
	
  
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}


@media all and (min-width: 320px) and (max-width: 767px) {
.s-sort {
    padding: 15px 24px;
    font-size: 13px;
    border-radius: 15px !important;
    margin-bottom: 10px !important;
}
.mobilerable-retuen .bnAwAJ {
    max-width: 70%;
    min-width: 70px;
}
.mobilerable-retuen .emGqmj div:first-child {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
}
.left-mob {
	float:left !important;
}
.promodecodetablenlast .emGqmj div:first-child{
	white-space: initial;
    overflow: initial;
    text-overflow: initial;
}
.promodecodetablenlast .gFAYxZ {
    min-width: 10%;
    max-width: 10%;
}

.padding-box {
    padding: 0px 10px;
    padding-top: 20px;
}
.navbar {
   padding: 0px 10px !important;
}
.card {
    margin-top: 15px;
}
.mt29 {
    margin-top: 0px;
}
.float-right {
    float: right !important;
    margin-left: 0px !important;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: none !important;
}
.popover {
	border:none !important;
}
.popover .card {
    margin-bottom: 0px !important;
	 margin-top: 0px !important;
}
.padding-box .row .col, 
.padding-box .row > [class^=col-] {
    margin-bottom: 0rem !important;
}
.padding-box .mt-4, .padding-box .my-4 {
    margin-top: 0.5rem !important;
}
.padding-box .card-body .card {
    margin-top: 0px;
}


}