.invoice {
    padding: 30px;
}

.invoice h2 {
	margin-top: 0px;
	line-height: 0.8em;
}

.invoice .small {
	font-weight: 300;
}

.invoice hr {
	margin-top: 10px;
	border-color: #ddd;
}

.invoice .table tr.line {
	border-bottom: 1px solid #ccc;
}

.invoice .table td {
	border: none;
}

.invoice .identity {
	margin-top: 10px;
	font-size: 1.1em;
	font-weight: 300;
}

.invoice .identity strong {
	font-weight: 600;
}


.grid {
    position: relative;
	width: 100%;
	background: #fff;
	color: #666666;
	border-radius: 2px;
	margin-bottom: 25px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}


.selcrbox {
	border: 1px solid #ddd; padding: 5px; border-radius: 5px; font-size: 12px;
}
.selcrbox:focus {
	outline:none;
}
.font-13 { font-size:13px;}
.table thead th {
    font-size: 13px;
}
.statusbg {
    background: #fd6a7e;
    color: #fff;
    padding: 2px 10px;
    border-radius: 100px;
    font-size: 11px;
}
.pname {
	width: 230px;
    display: inline-block;
    word-break: break-word;
    padding-right: 0px;
}
