
.languages {
    display: none;
    position: relative;
    margin: 0;
    background: #dddddd;
}

.languages > li {
    background: #eee;
    line-height: 25px;
    font-size: 14px;
    padding: 0 10px;
    cursor: pointer;
}

.languages > li > div:hover {
    background: #aaa;
}

.sizeInputBox{
    width: 100%;
    min-height: 90px;
    max-height : 200px;
    border: 1px solid #ddd;
    position: relative;
    top: 0px;
    background : #fff;
    overflow: auto;
}
.sizeInputBox::-webkit-scrollbar{
    width : 4px;
}
.sizeInputBox::-webkit-scrollbar-track{
    background : #fff;
}
.sizeInputBox::-webkit-scrollbar-thumb{
    background : rgb(228, 220, 220);
    border-radius: 10px;
}

.options{
    margin-top : 15px;
}
.options > button{
    border: 1px solid pink;
    outline: none;
    background: #fff;
    padding: 4px 8px;
    margin : 6px;
    border-radius: 4px;
    cursor : pointer;
    font-size: 14px;
    user-select: none;
}

.sizeTypeTab {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    position : sticky;
    top :0; 
    width : 100%;
    user-select : none;
}

.sizeTypeTab > span{
    background: #fff;
    width: 100%;
    text-align: center;
    padding: 5px;
    cursor : pointer;
    font-size: 12px;
    border-right : 1px solid #ddd;
    transition : background 0.2s;
}

.sizeTypeTab span :nth-last-child(){
    border-right : none !important;
}
.selected{
    background : pink !important;
}
.addSizeBtn{
    width: 60px;
    height: 100%;
    margin-top: 30px;
}
.errorMsg{
    font-size: 12px;
    color: rgb(233, 68, 68);
}

.closeInput{
    float: right;
    margin: 0 10px;
    font-size: 12px;
    color : #d87093;
    padding: 2px 5px;
    cursor : pointer;
}

.customSizeBtn{
    font-weight : 600;
    color : #d87093;
}
.customField{
    display: flex;
    margin : 25px;
}
.customField Button{
    margin-left : 10px;
}
.sizeInput{
    background : #fff !important;
}

.sizeListItem{
    display : flex;

}
.label1{
    color : grey;
}
.label2{
    font-weight: 600;
}
.sizeListItem{
    display: flex;
    width : 100%;
    margin : 15px 0;
    padding: 8px 0;
}
.sizeArea{
    width : 40%;
}
.avlQuantityArea{
    width : 40%;
}
.removeSize{
    font-size: 16px;
    cursor : pointer;
}

.brands {
    position: relative;
    margin: 0;
    background: #dddddd;
}
.brands > li {
    background: #eee;
    line-height: 25px;
    font-size: 14px;
    padding: 0 10px;
    cursor: pointer;
}

.drag-area {
    height: 150px;
    border: 2px dashed #e0eafc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .drag-area.active {
    border: 2px solid #1683ff;
  }
  .drag-area .header {
    font-size: 12px;
    font-weight: 500;
    color: #34495e;
  }
  .drag-area img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
  .closeButtonClass{
    position: absolute;
    font-size: 21px;
    background: #e5638e;
    top: -5px;
    right: 10px;
    border-radius: 50%;
    padding: 3px;
    background: white;
    box-shadow: 0 0 8px #ddd;
    cursor: pointer;
}
.colClass{
    position: relative;
    height: 150px;
    margin-bottom: 10px;
}
.detail-heading{
    font-weight: 500;
}
/* .spinnerParentDiv{
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 99;
    width: 100%;
    background: rgba(255,255,255,0.6);
    height: 100vh;
} */